import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import List from './List';
import CategoriesSelector from './CategoriesSelector';
import {
  margins, colors, contentWidth, mobileThresholdPixels,
}
  from '../../tilli-components/src/components/styledComponents';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${contentWidth - 200}px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
    align-items: center;
  }
`;

const Text = styled.p`
  text-align: center;
  color: ${colors.navy};
  font-size: 12px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} ${margins.s} 0px;
    text-align: justify;
  }
`;

const LaRubriqueListContent = ({
  categories, category, posts, context: { isMobile },
}) => {
  if (isMobile) {
    return (
      <Column>
        <List posts={posts} />
        <CategoriesSelector
          categories={categories}
          selectedCategorySlug={category && category.slug}
        />
        {(category && category.text) && <Text>{category.text}</Text>}
      </Column>
    );
  }
  return (
    <Row>
      <CategoriesSelector
        categories={categories}
        selectedCategorySlug={category && category.slug}
      />
      <Column>
        {(category && category.text) && <Text>{category.text}</Text>}
        <List posts={posts} />
      </Column>
    </Row>
  );
};

LaRubriqueListContent.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  category: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

LaRubriqueListContent.defaultProps = {
  categories: [],
  category: null,
};

export default withAppContext(LaRubriqueListContent);
