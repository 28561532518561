import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListPost from './ListPost';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const List = ({ posts }) => (
  <Container>
    <Content>
      {posts.map((post) => <ListPost post={post} key={post.title} />)}
    </Content>
  </Container>
);

List.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default List;
