import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  colors, margins, fontSizes, mobileThresholdPixels,
} from '../styledComponents';

const imageWidth = 268;
const Img = styled.img`
  width: ${imageWidth}px;
  height: 400px;
  object-fit: cover;

  @media (max-width: ${mobileThresholdPixels}) {
    width: ${imageWidth / 2}px;
    height: 200px;
  }
`;

const Content = styled.div`
  position: relative;
  margin: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s};
  }
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 0px;
  background-color: white;
  margin: 0px 20px;
  width: ${imageWidth - (2 * 20)}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 10px;
    width: ${(imageWidth / 2) - (2 * 10)}px;
  }
`;

const Title = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  margin: ${margins.xs} 0px;
  color: ${colors.navy};
  font-size: ${fontSizes.m};
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const Subtitle = styled.span`
  font-weight: 300;
  text-transform: uppercase;
  color: ${colors.navy};
  margin: ${margins.xs} 0px;
  font-size: ${fontSizes.m};
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const ListPost = ({ post }) => (
  <Content>
    <Img src={post.listImage && post.listImage.publicURL} alt={post.listImageAlt} />
    <Link to={post.path} style={{ textDecoration: 'none' }}>
      <Overlay>
        <Subtitle>{post.category}</Subtitle>
        <Title>{post.title}</Title>
      </Overlay>
    </Link>
  </Content>
);

ListPost.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    listImageAlt: PropTypes.string.isRequired,
    listImage: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ListPost;
